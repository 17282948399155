import React, { useState, useContext, useEffect } from "react";
import { Context } from "../context/context";
import Breadcrumb from "../components/Breadcrumb";
import { useLocation } from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";

const Layout = (props) => {
  const {
    state: {
      auth: { isAuthenticated },
    },
  } = useContext(Context);

  const location = useLocation();

  const [currentLocation, setCurrentLocation] = useState(location.pathname.replace(/\\|\//g, "").toLowerCase());

  useEffect(() => {
    setCurrentLocation(location.pathname.replace(/\\|\//g, "").toLowerCase());
  }, [location]);
  return (
    <>
      <Header />
      <main role="main" className={isAuthenticated ? `${currentLocation}-page` : `login-page`}>
        <div className={isAuthenticated ? (currentLocation === "congratulations" ? `congratulations-page-background ` : `${currentLocation}-page-background page-background`) : `login-page-background`}>
          <div className="container pt-5">
            <div className="form-row">
              {isAuthenticated && <Breadcrumb />}
              <div className="col-12 px-0">{props.children}</div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Layout;
